<div class="count">{{data?.counterLabel}} - {{ dataSource?.filteredData.length }}</div>
<div class="table-container mat-elevation-z15">    
    <table mat-table [dataSource]="dataSource" matSort>
        <ng-container [matColumnDef]="col.fieldName" *ngFor="let col of data.columns;">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{col.displayName}} </th>
            <td mat-cell *matCellDef="let element" [ngSwitch]="col.columnType">
                <span *ngSwitchCase="'Hyperlink' || 'hyperlink'">
                    <a class="hyperlink" (click)="navigateURL(element[col.fieldName], col.fieldName)">
                        {{element[col.fieldName]}}
                    </a>
                </span>
                <span *ngSwitchDefault>
                    {{element[col.fieldName]}}
                </span>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="data.columnDisplayNames"></tr>
        <tr mat-row *matRowDef="let row; columns: data.columnDisplayNames;"></tr>
    </table>
</div>