import { IPanel,ISection } from "../../../interfaces";
import { Section } from "./view-config-section.model";

export class VCPanel {
    private _panelName: string;
    private _panelMessage: string;
    private _sections: Array<Section> = [];

    constructor({ ...options }: IPanel) {
        this.set(options);
    }

    set<T extends IPanel>({ ...options }: T): void {
        this._panelName = options.panelName;
        this._panelMessage = options.panelMessage;
        options.sections.forEach((elem:ISection) => {
            this._sections.push(new Section(elem));
        });
    }

    get panelName() {
        return this._panelName;
    }

    get panelMessage() {
        return this._panelMessage;
    }

    get sections() {
        return this._sections;
    }
}