import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { END_POINTS } from '../../constants/end-points.constants';

@Injectable({
  providedIn: 'root',
})
export class FormConfigService {
  constructor(private http: HttpClient) {}

  getDropdownOptions(data) {
    const uri = `${environment.NODE_SERVICE}${END_POINTS.BASE_URL}/form-config/dropdown-options`;
    return this.http.post(uri, { ...data });
  }
}
