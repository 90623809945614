import { IFormConfig } from '../../../interfaces';
import { Buttons } from '../viewConfig/button.model';

export class EditConfig {
    private _jsonId: number = null;
    private _jsonContent: Object = null;
    private _description: string = null;
    private _action: string = null;
    private _recordValue = null;
    private _recordFieldName = null;
    private _tableName: string = null;
    private _buttons: Buttons[] = [];

    constructor(options?: IFormConfig) {
        if (options) {
            this.set(options);
        }
    }

    set<T extends IFormConfig>({ ...options }: T): void {
        this._jsonId = options.jsonId;
        this._jsonContent = options.jsonContent;
        this._description = options.description;
    }

    get jsonId() {
        return this._jsonId;
    }

    get jsonContent() {
        return this._jsonContent;
    }

    get description() {
        return this._description;
    }

    get recordValue() {
        return this._recordValue;
    }

    set recordValue(data) {
        this._recordValue = data;
    }

    get action() {
        return this._action;
    }

    set action(data) {
        this._action = data;
    }

    get recordFieldName() {
        return this._recordFieldName;
    }

    set recordFieldName(data) {
        this._recordFieldName = data;
    }

    get tableName() {
        return this._tableName;
    }

    set tableName(data) {
        this._tableName = data;
    }

    set buttons(args: Array<any>) {
        this._buttons = [];
        args.forEach((p: any) => {
            this._buttons.push(new Buttons(p));
        });
    }

    get buttons(){
        return this._buttons;
    }

    toJSON() {
        return {
            jsonId: this._jsonId,
            jsonContent: this._jsonContent,
            description: this._description,
            recordValue: this._recordValue,
            action: this._action,
            recordFieldName: this._recordFieldName,
            tableName: this._tableName,
        };
    }
}
