<div [ngSwitch]="page">
    <span *ngSwitchCase="pageTypes.INDEX">
        <app-index-config></app-index-config>
    </span>
    <span *ngSwitchCase="pageTypes.EDIT">
        <app-edit-config></app-edit-config>
    </span>
    <span *ngSwitchCase="pageTypes.VIEW">
        <app-view-config></app-view-config>
    </span>
    <span *ngSwitchCase="pageTypes.DASHBOARD">
        <app-dashboard-config></app-dashboard-config>
    </span>
    
    <!-- <span *ngSwitchDefault>
        show page not found
    </span> -->
</div>