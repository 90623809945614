import { ITooltips } from "src/app/shared/interfaces/widget-config/widget-config.interface";

export class Tooltips {
    private _showTooltips: boolean;
    private _tooltipContent: Array<string>;

    constructor({ ...options }: ITooltips) {
        this.set(options);
    }


    set({ ...options }: ITooltips) {
        this._showTooltips = options.showTooltips;
        this._tooltipContent = options.tooltipContent;
    }


    get showTooltips() {
        return this._showTooltips;
    }


    get tooltipContent() {
        return this._tooltipContent;
    }

}