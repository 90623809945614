import { IYAxis } from "src/app/shared/interfaces/widget-config/widget-config.interface"; 

export class YAxis {
    private _title: boolean;
    private _position: string;
    private _gridlines: boolean;
    private _lineColor: string;
    private _lineStyle: string;
    private _labels: {
        fontColor: string;
        fontSize: number;
    };

    constructor({ ...options }: IYAxis) {
        this.set(options);
    }

    set({ ...options }: IYAxis) {
        this._title = options.title;
        this._position = options.position;
        this._gridlines = options.gridlines;
        this._lineColor = options.lineColor;
        this._lineStyle = options.lineStyle;
        this._labels = options.labels;
    }

    get title(): boolean {
        return this._title;
    }

    get position(): string {
        return this._position;
    }

    get gridlines(): boolean {
        return this._gridlines;
    }


    get lineColor(): string {
        return this._lineColor;
    }

    get lineStyle(): string {
        return this._lineStyle;
    }

    get labels(): {
        fontColor: string;
        fontSize: number;
    } {
        return this._labels;
    }



}