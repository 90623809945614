import { Component } from '@angular/core';
import { DashboardConfigPanel } from 'src/app/shared/model/page/dashboardConfig/dashboard-config/dashboard-config-panel.model';
import { PageService } from 'src/app/shared/services/page/page.service';

@Component({
  selector: 'app-dashboard-config',
  templateUrl: './dashboard-config.component.html',
  styleUrls: ['./dashboard-config.component.css'],
})
export class DashboardConfigComponent {
  panels: DashboardConfigPanel;
  title = '';

  constructor(private pageSvc: PageService) {}

  ngOnInit() {
    console.log(this.pageSvc.page.dashboardConfig.jsonId);
    // if the json id is present then the page details was already loaded if not get the json details
    if (this.pageSvc.page.dashboardConfig.jsonId) {
      // set the loaded data in component
      this.processPageMetaData(this.pageSvc.page.dashboardConfig.toJSON());
    } else {
      this.getPageMetadata();
    }
  }

  getPageMetadata() {
    this.pageSvc.getPageMetadata(this.pageSvc.pageDetails.dashboardJsonId).subscribe((resp: any) => {
      console.log('resp->>>', resp);
      this.processPageMetaData(resp);
      console.log(this.panels);
      // console.log(resp.jsonContent.page.panels);
      // const DCConfig = new DashboardConfigPanel(resp.jsonContent.page.panels[0]);
      // console.log("DCConfig --------------->", DCConfig);
    });
  }

  processPageMetaData(resp) {
    if (resp) {
      this.pageSvc.page.setDashboardConfig(resp);
      this.title = resp.jsonContent.page.pageTitle;
      this.pageSvc.page.dashboardConfig.panels = resp.jsonContent.page.panels[0];
      this.panels = this.pageSvc.page.dashboardConfig.panels;
    }
  }
}
