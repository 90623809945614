import { SubMenu } from './sub-menu.model';

interface IMenu {
    title: string;
    expandable: boolean;
    iconClass: string;
    path: string;
    subMenus: SubMenu[];
    defaultPage: string;
    pageId: string;
}

export class Menu {
    private _title: string = '';
    private _expandable: boolean = false;
    private _iconClass: string = '';
    private _path: string = '';
    private _subMenus: SubMenu[] = [];
    private _defaultPage: string = '';
    private _pageId: string = '';

    constructor(options?: IMenu) {
        if (options) {
            this.set(options);
        }
    }

    //Leads;Users;Access_Control;Missing_Forms;Transactions;Patients;Unsigned_Encounters;Open_Orders;Dashboard;Appointments;Error_Logs;Subscriptions;Support_Tickets;Patients#All_Patients;Patients#New_Patients
    set<T extends IMenu>({ ...options }: T): void {
        this._title = options.title;
        this._expandable = options.expandable;
        this._iconClass = options.iconClass;
        this._path = options.path;
        this._subMenus = options.subMenus;
        this._defaultPage = options.defaultPage;
        this._pageId = options.pageId;
    }

    get title() {
        return this._title;
    }

    set title(title: string) {
        this._title = title;
    }

    get expandable() {
        return this._expandable;
    }

    set expandable(expandable: boolean) {
        this._expandable = expandable;
    }

    get iconClass() {
        return this._iconClass;
    }

    set iconClass(iconClass: string) {
        this._iconClass = iconClass;
    }

    get path() {
        return this._path;
    }

    set path(path: string) {
        this._path = path;
    }

    get subMenus() {
        return this._subMenus;
    }

    set subMenus(subMenu: SubMenu[]) {
        this._subMenus = subMenu;
    }

    get defaultPage() {
        return this._defaultPage;
    }

    set defaultPage(defaultPage: string) {
        this._defaultPage = defaultPage;
    }

    get pageId() {
        return this._pageId;
    }

    set pageId(pageId: string) {
        this._pageId = pageId;
    }

    // Method to add a single submenu
    addSubMenu(subMenuData: SubMenu) {
        this._subMenus.push(subMenuData);
    }
}
