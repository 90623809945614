import { IViewConfig } from '../../../interfaces';
import { Buttons } from './button.model';
import { VCPanel } from './view-config-panel.model';

export class ViewConfig {
    private _jsonId: number = null;
    private _jsonContent: Object = null;
    private _description: string = null;
    private _panels: VCPanel[] = [];
    private _recordId: string = null;
    private _recordFieldName: string = null;
    private _tableName: string = null;
    private _buttons: Buttons[] = []

    constructor(options?: IViewConfig) {
        if (options) {
            this.set(options);
        }
    }

    set<T extends IViewConfig>({ ...options }: T): void {
        this._jsonId = options.jsonId;
        this._jsonContent = options.jsonContent;
        this._description = options.description;
    }

    get jsonId() {
        return this._jsonId;
    }

    get jsonContent() {
        return this._jsonContent;
    }

    get description() {
        return this._description;
    }

    get panels() {
        return this._panels;
    }

    set panels(args: Array<any>) {
        this._panels = [];
        args.forEach((p: any) => {
            this._panels.push(new VCPanel(p));
        });
    }

    set buttons(args: Array<any>) {
        this._buttons = [];
        args.forEach((b: any) => {
            this._buttons.push(new Buttons(b));
        })
    }

    get buttons() {
        return this._buttons;
    }

    get recordId() {
        return this._recordId;
    }

    set recordId(data) {
        this._recordId = data;
    }

    get recordFieldName() {
        return this._recordFieldName;
    }

    set recordFieldName(data) {
        this._recordFieldName = data;
    }

    get tableName() {
        return this._tableName;
    }

    set tableName(data) {
        this._tableName = data;
    }

    toJSON() {
        return {
            jsonId: this._jsonId,
            jsonContent: this._jsonContent,
            description: this._description,
            panels: this._panels,
            recordId: this._recordId,
            recordFieldName: this._recordFieldName,
            tableName: this._tableName,
        };
    }
}
