import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  Observable,
  Subject,
  Subscription,
  fromEvent,
  merge,
  timer,
} from 'rxjs';
import { Session } from '../../model/session.model';
import { CryptoService } from '../crytoservice/crypto-service.service';
import { environment } from 'src/environments/environment';
import { END_POINTS } from '../../constants/end-points.constants';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { LoggedinUserService } from '../loggedin-user/loggedin-user.service';
import { AlertPopupComponent } from '../../components/popups/alert-popup/alert-popup.component';

interface IAccessTokenService {
  accessToken: string;
  accessTokenExp: string;
}

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  hasDialogOpened = false;
  idle$: Observable<any>;
  timer$: Subscription;
  timeOutMS: number; // timeout in milliseconds
  idleSubscription: Subscription;
  expired$: Subject<boolean> = new Subject<boolean>();
  tokenTimer$: Subscription;

  private static _session: Session = new Session();

  constructor(
    private http: HttpClient,
    private loggedInUserSvc: LoggedinUserService,
    private dialog: MatDialog
  ) {}

  // Getter for session data
  get session() {
    return SessionService._session;
  }

  // Setter for session data
  set session(sessionData: Session) {
    if (this.isTokenValid(sessionData.accessTokenExp)) {
      console.log('token is valid');
      const data = sessionData.getSessionData();
      localStorage.setItem(
        'session',
        CryptoService.encryptUsingAES256(JSON.stringify(data))
      );
      SessionService._session = sessionData;
      this.startWatching(15).subscribe((data) => {
        if (data) {
          this.showDialogBox();
        }
      });
    } else {
      this.removeSession();
      console.log('access token is not valid');
      this.expired$.next(true);
    }
  }

  removeSession() {
    this.stopTimer();
    localStorage.removeItem('session');
  }

  showDialogBox() {
    console.log('user log out dialog box');
    this.removeSession();
    this.loggedInUserSvc.logout();
  }

  isTokenValid(tokenExp) {
    const isValid = new Date(tokenExp * 1000) > new Date();
    return isValid;
  }

  startWatching(timeOutMinutes: number): Observable<any> {
    this.idle$ = merge(
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'click'),
      fromEvent(document, 'mousedown'),
      fromEvent(document, 'keypress'),
      fromEvent(document, 'DOMMouseScroll'),
      fromEvent(document, 'mousewheel'),
      fromEvent(document, 'touchmove'),
      fromEvent(document, 'MSPointerMove'),
      fromEvent(window, 'mousemove'),
      fromEvent(window, 'resize')
    );

    this.timeOutMS = timeOutMinutes * 60 * 1000;

    this.idleSubscription = this.idle$.subscribe((res) => {
      this.resetTimer();
    });

    this.startTimer();
    this.accessTokenTimer();
    return this.expired$;
  }

  startTimer() {
    this.timer$ = timer(this.timeOutMS, this.timeOutMS).subscribe((res) => {
      console.log('user inactive');
      this.expired$.next(true);
    });
  }

  accessTokenTimer() {
    // get new access token after 14 minutes
    const minutes = 14;
    const millisec = minutes * 60 * 1000;
    this.tokenTimer$ = timer(millisec, millisec).subscribe((res) => {
      if (this.isTokenValid(SessionService._session.refreshTokenExp)) {
        // get the new token after 14 min and set
        this.getAccessToken().subscribe((token) => {});
      } else {
        console.log('refresh token is not valid');
        this.expired$.next(true);
      }
    });
  }

  public resetTimer() {
    this.timer$.unsubscribe();
    this.startTimer();
  }

  public stopTimer() {
    if (this.timer$) {
      this.timer$.unsubscribe();
    }

    if (this.idleSubscription) {
      this.idleSubscription.unsubscribe();
    }

    if (this.tokenTimer$) {
      this.tokenTimer$.unsubscribe();
    }
  }

  showLogoutPopup() {
    const dialogRef = this.dialog.open(AlertPopupComponent, {
      data: {
        title: 'Session Timeout',
        body: `User Session Timeout. Please login again...`,
        okButtonLabel: 'OK',
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.loggedInUserSvc.logout();
      }
    });
  }

  getAccessToken() {
    const uri = `${environment.NODE_SERVICE}${END_POINTS.BASE_URL}/refresh`;
    const postData = {
      refreshToken: SessionService._session.refreshToken,
    };
    return this.http.post(uri, postData).pipe(
      map((res: IAccessTokenService) => {
        SessionService._session.accessToken = res.accessToken;
        SessionService._session.accessTokenExp = res.accessTokenExp;
        return res.accessToken;
      })
    );
  }

  getNewSessionTokens() {
    const uri = `${environment.NODE_SERVICE}${END_POINTS.BASE_URL}/new-session`;
    const user = this.loggedInUserSvc.user.getUserData();
    return this.http.post(uri, { user });
  }
}
