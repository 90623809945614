import { IDCSection, IWidgets } from '../../../../interfaces/dashboard-page.interface'
import { Widgets } from "./dashboard-config-widgets.model";

export class DashboardConfigSection {
    private _sectionName: string;
    private _widgets: Array<Widgets> = [];

    constructor({ ...options }: IDCSection) {
        this.set(options);
    }

    set({ ...options }: IDCSection) {
        this._sectionName = options.sectionName;
        options.widgets.forEach((elem: IWidgets) => {
            this._widgets.push(new Widgets(elem));
        })
    }

    get sectionName() {
        return this._sectionName;
    }

    get widgets() {
        return this._widgets;
    }
}
