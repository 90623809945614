//MODELS
import { EditConfig, IndexConfig, ViewConfig } from '..';
import { DashboardConfig } from './dashboardConfig/dashboard-config/dashboard-config.model';

export class PageConfig {
    private _indexConfig: IndexConfig = new IndexConfig();
    private _formConfig: EditConfig = new EditConfig();
    private _viewConfig: ViewConfig = new ViewConfig();
    private _dashboardConfig: DashboardConfig = new DashboardConfig();

    constructor() {}

    get indexConfig() {
        return this._indexConfig;
    }

    setIndexConfig(args) {
        this._indexConfig = new IndexConfig(args);
    }

    get formConfig() {
        return this._formConfig;
    }

    setFormConfig(args) {
        this._formConfig = new EditConfig(args);
    }

    get viewConfig() {
        return this._viewConfig;
    }

    setViewConfig(args) {
        this._viewConfig = new ViewConfig(args);
    }


    setDashboardConfig(args) {
        this._dashboardConfig = new DashboardConfig(args);
    }

    get dashboardConfig() {
        return this._dashboardConfig;
    }

    toJSON() {
        return {
            indexConfig: this._indexConfig.toJSON(),
            formConfig: this._formConfig.toJSON(),
            viewConfig: this._viewConfig.toJSON(),
            dashboardConfig: this._dashboardConfig.toJSON()
        };
    }
}
