import { Component, Input } from '@angular/core';
import { WidgetConfig } from 'src/app/shared/model/page/dashboardConfig/widget-config/widget-config.model';
import * as Highcharts from 'highcharts';
import { ExecuteQueryService } from 'src/app/shared/services/execute-query/execute-query.service';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css']
})
export class BarChartComponent {
  @Input() widgetConfig: WidgetConfig;
  dataSource: any = [];
  datas: any = [];
  seriesValue: any = [];
  keyValue: any = [];
  options: any;

  constructor(private querySvc: ExecuteQueryService) { }

  ngOnInit() {
    console.log("WidgetConfig", this.widgetConfig);
    this.getData();
  }

  initializeChart(): void {
    console.log(this.dataSource);

    this.options = {
      chart: {
        type: this.widgetConfig.widgetType === 'bar_chart' ? 'column' : 'line',
        backgroundColor: this.widgetConfig.backgroundColor,
      },
      title: {
        text: this.widgetConfig.title,
      },
      xAxis: {
        categories: this.getCategories(),
        title: {
          text: this.widgetConfig.xAxis.title ? this.widgetConfig.xAxisLabel : null,
        },
        gridLineWidth: this.widgetConfig.xAxis.gridlines ? 1 : 0,
        // min: Number(this.widgetConfig.xAxis.startAndEnd.start),  // Set start point
        // max: Number(this.widgetConfig.xAxis.startAndEnd.end),    // Set end point
        lineColor: this.widgetConfig.xAxis.lineColor,  
        lineWidth: 1,  
        tickColor: this.widgetConfig.xAxis.lineColor,  
        dashStyle: this.widgetConfig.xAxis.lineStyle,   // Set line style (solid, dashed, etc.)
        labels: {
          style: {
            color: this.widgetConfig.xAxis.lineColor,
          },
        },
      },
      yAxis: {
        title: {
          text: this.widgetConfig.yAxis.title ? this.widgetConfig.yAxisLabel : null,
        },

        gridLineWidth: this.widgetConfig.yAxis.gridlines ? 1 : 0,
        lineColor: this.widgetConfig.yAxis.lineColor,

        labels: {
          style: {
            color: this.widgetConfig.yAxis.labels.fontColor,
            fontSize: `${this.widgetConfig.yAxis.labels.fontSize}px`,
          },
        },
      },
      legend: {
        enabled: this.widgetConfig.legend.showLegend,
        align: 'center',
        verticalAlign: this.widgetConfig.legend.position,
      },
      series: [
        {
          name: this.widgetConfig.yAxisLabel,
          data: this.processDataSource(),
          type: 'column',
          color: this.widgetConfig.dataColors,
        }
      ],
      plotOptions: {
        column: {
          pointPadding: 0.2,  // space between bars
          groupPadding: 0.1,  // space between different series
          pointWidth: 30,
          borderWidth: 0,
        },
        series: {
          stacking: 'normal',
          // colorByPoint: true,
          pointWidth: this.widgetConfig.barWidth,
        }
      },
      tooltip: {
        enabled: this.widgetConfig.tooltips.showTooltips,
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: ' {point.y}<br/>',
        // formatter: function () {
        //   return this.widgetConfig.tooltips.tooltipContent.map((field: string) => `${field}: ${this.point[field]}`).join('<br>');
        // },
      },
      exporting: {
        enabled: this.widgetConfig.exportSettings.exportToExcel || this.widgetConfig.exportSettings.exportToPDF,
      },

      credits: {
        enabled: false
      },
    };
    console.log(this.options);

    Highcharts.chart(this.widgetConfig.widgetId, this.options);
  }

  getData() {
    this.querySvc.executeQuery(this.widgetConfig.query).subscribe((resp: any) => {
      this.processData(resp);
    })
  }

  processData(resp) {
    this.dataSource = Object.assign([], resp);
    console.log(this.dataSource);
    this.initializeChart();
  }

  processDataSource() {
    return this.dataSource.map(item => {
      return {
        name: item.state,
        y: item.client_count
      };
    });
  }

  getCategories() {
    return this.dataSource.map(item => {
      return item.state;
    });
  }
}
