import { IWidgetConfig } from "src/app/shared/interfaces/widget-config/widget-config.interface";
import { DataLabels } from "./dataLabels.model";
import { ExportSettings } from "./exportSetting.model";
import { Legend } from "./legend.model";
import { Tooltips } from "./tooltips.model";
import { XAxis } from "./xAxis.model";
import { YAxis } from "./yAxis.model";

export class WidgetConfig {
    private _widgetType: string;
    private _widgetId: string;
    private _widgetWidth: string;
    private _marginTop: number;
    private _title: string;
    private _tableName: string;
    private _query: string;
    private _xAxisLabel: string;
    private _yAxisLabel: string;
    private _xAxisData: string;
    private _yAxisData: string;
    private _orientation: string;
    private _dataColors: string;
    private _barWidth: number;
    private _barSpacing: number;
    private _backgroundColor: string;
    private _shadow: boolean;
    private _tooltips: Tooltips;
    private _legend: Legend;
    private _xAxis: XAxis;
    private _yAxis: YAxis;
    private _dataLabels: DataLabels;
    private _exportSettings: ExportSettings


    constructor({ ...options }: IWidgetConfig) {
        this.set(options);
    }

    set({ ...options }: IWidgetConfig) {
        this._widgetType = options.widgetType;
        this._widgetId = options.widgetId;
        this._widgetWidth = options.widgetWidth;
        this._marginTop = options.marginTop;
        this._title = options.title;
        this._tableName = options.tableName;
        this._query = options.query;
        this._xAxisLabel = options.xAxisLabel;
        this._yAxisLabel = options.yAxisLabel;
        this._xAxisData = options.xAxisData;
        this._yAxisData = options.yAxisData;
        this._orientation = options.orientation;
        this._dataColors = options.dataColors;
        this._barWidth = options.barWidth;
        this._barSpacing = options.barSpacing;
        this._backgroundColor = options.backgroundColor;
        this._shadow = options.shadow;
        this._tooltips = new Tooltips(options.tooltips);
        this._legend = new Legend(options.legend);
        this._xAxis = new XAxis(options.xAxis);
        this._yAxis = new YAxis(options.yAxis);
        this._dataLabels = new DataLabels(options.dataLabels);
        this._exportSettings = new ExportSettings(options.exportSettings);
    }

    get widgetType(): string {
        return this._widgetType;
    }

    get widgetId(): string {
        return this._widgetId;
    }

    get widgetWidth(): string {
        return this._widgetWidth;
    }

    get marginTop(): number {
        return this._marginTop;
    }

    get title(): string {
        return this._title;
    }

    get tableName(): string {
        return this._tableName;
    }

    get query(): string {
        return this._query;
    }

    get xAxisLabel(): string {
        return this._xAxisLabel;
    }

    get yAxisLabel(): string {
        return this._yAxisLabel;
    }

    get xAxisData(): string {
        return this._xAxisData;
    }

    get yAxisData(): string {
        return this._yAxisData;
    }

    get orientation(): string {
        return this._orientation;
    }

    get dataColors(): string {
        return this._dataColors;
    }

    get barWidth(): number {
        return this._barWidth;
    }

    get barSpacing(): number {
        return this._barSpacing;
    }

    get backgroundColor(): string {
        return this._backgroundColor;
    }

    get shadow(): boolean {
        return this._shadow;
    }

    get tooltips(): Tooltips {
        return this._tooltips;
    }

    get legend(): Legend {
        return this._legend;
    }

    get xAxis(): XAxis {
        return this._xAxis;
    }

    get yAxis(): YAxis {
        return this._yAxis;
    }

    get dataLabels(): DataLabels {
        return this._dataLabels;
    }

    get exportSettings(): ExportSettings {
        return this._exportSettings;
    }


}