export const ACTION_TYPE = {
  UPDATE: 'Update',
  NEW: 'New',
};

export const SECTION_TYPE = {
  GRID: 'grid',
  FORM: 'form',
};

export const PAGE_TYPE = {
  INDEX: 'Index',
  EDIT: 'Edit',
  VIEW: 'View',
  DASHBOARD: 'Dashboard',
};
