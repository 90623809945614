import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PAGE_TYPE } from 'src/app/shared/constants/page/page.constants';
import { PageService } from 'src/app/shared/services/page/page.service';

@Component({
  selector: 'app-base-config',
  templateUrl: './base-config.component.html',
  styleUrls: ['./base-config.component.css'],
  providers: [PageService],
})
export class BaseConfigComponent implements OnInit {
  page = '';
  pageTypes = PAGE_TYPE;
  pageObserver: Subscription;

  constructor(private pageSvc: PageService) {}

  ngOnInit(): void {
    this.pageSvc.getPage();

    this.pageObserver = this.pageSvc.currentPageObserver.subscribe((page) => {
      this.page = this.pageSvc.currentPage;
    });
  }

  ngOnDestroy() {
    if (this.pageObserver) {
      this.pageObserver.unsubscribe();
    }
  }
}
