import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-custom-panel',
  templateUrl: './custom-panel.component.html',
  styleUrls: ['./custom-panel.component.css']
})
export class CustomPanelComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() expand:boolean = false;
  panelOpenState: boolean;
  constructor() {}
}
