<app-header [pageTitle]="title">
</app-header>

<div *ngIf="panels">
    <!-- <div *ngFor="let panel of panels; let i=index "> -->
    <app-custom-panel title={{panels.panelName()}} expand="true" theme="primary">
        <div class="panel-message">
            <h3>{{panels.panelMessage()}}</h3>
        </div>
        <div class="section" *ngFor="let section of panels.sections(); let j=index;">
            <div>
                <h3 class="title">{{section.sectionName}}</h3>
            </div>
            <div class="row" *ngFor="let widget of section.widgets;">

                <div *ngIf="widget.widgetConfig.widgetType === 'bar_chart'">
                    <div [class]="{'col-md-12': widget.widgetConfig.widgetWidth === 'full', 
                        'col-md-6': widget.widgetConfig.widgetWidth === 'half',
                    }">
                        <app-bar-chart [widgetConfig]="widget.widgetConfig"></app-bar-chart>
                    </div>
                </div>
            </div>
            <!-- <app-section-grid [data]="section"></app-section-grid> -->
        </div>
    </app-custom-panel>
</div>
<!-- </div> -->