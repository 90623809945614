import { Component } from '@angular/core';
import { ACTION_TYPE, PAGE_TYPE } from 'src/app/shared/constants/page/page.constants';
import { IButton } from 'src/app/shared/interfaces/button.interface';
import { ExecuteQueryService } from 'src/app/shared/services/execute-query/execute-query.service';
import { PageService } from 'src/app/shared/services/page/page.service';

@Component({
  selector: 'app-view-config',
  templateUrl: './view-config.component.html',
  styleUrls: ['./view-config.component.css'],
})
export class ViewConfigComponent {
  id = null;
  fieldName = null;
  rows = [];
  data: any;
  title: any;
  jsonId: Number;

  showPanel = false;
  pageTitle: string;
  panels = [];
  buttons: IButton[] = [];
  tableName = '';

  headerBtnConfig = [
    { name: 'Edit', action: 'edit' },
    { name: '<< Back', class: 'primary', action: 'back' }
  ]

  constructor(
    private pageSvc: PageService,
    private executeQuerySvc: ExecuteQueryService
  ) {}

  ngOnInit() {
    this.jsonId = this.pageSvc.pageDetails.viewPageJsonId;
    this.id = this.pageSvc.page.viewConfig.recordId;
    this.fieldName = this.pageSvc.page.viewConfig.recordFieldName;

    // if the json id is present then the page details was already loaded if not get the json details
    if (this.pageSvc.page.viewConfig.jsonId) {
      // set the loaded data in component
      this.processPageMetaData(this.pageSvc.page.viewConfig.toJSON());
    } else {
      this.getPageMetadata();
    }
  }

  getPageMetadata() {
    this.pageSvc.getPageMetadata(this.jsonId).subscribe((resp: any) => {
      console.log('view page Metadata: ', resp);
      this.processPageMetaData(resp);
    });
  }

  processPageMetaData(resp: any) {
    this.title = resp.jsonContent.pageTitle;
    this.rows = resp.jsonContent.panels[0].sections[0].rows;

    this.pageSvc.page.setViewConfig(resp);
    this.pageTitle = resp.jsonContent.pageTitle;
    this.pageSvc.page.viewConfig.panels = resp.jsonContent.panels;
    this.pageSvc.page.viewConfig.buttons = resp.jsonContent.buttons;
    this.panels = this.pageSvc.page.viewConfig.panels;
    this.buttons = this.pageSvc.page.viewConfig.buttons
    this.tableName = resp.jsonContent.panels[0].sections[0].tableName;

    this.getPageData();
  }

  getPageData() {
    const query = `select * from ${this.tableName} where ${this.fieldName} = ${this.id}`;

    this.executeQuerySvc.executeQuery(query).subscribe((data: any) => {
      if (data.length) {
        this.data = data[0];
        this.showPanel = true;
      }
    });
  }

  handleBack() {
    this.pageSvc.page.indexConfig.refreshData = false;
    this.pageSvc.currentPage = PAGE_TYPE.INDEX;
  }

  handleEdit() {
    this.pageSvc.page.formConfig.recordValue = this.id;
    this.pageSvc.page.formConfig.recordFieldName = this.fieldName;
    this.pageSvc.page.formConfig.action = ACTION_TYPE.UPDATE;
    this.pageSvc.currentPage = PAGE_TYPE.EDIT;
  }

  handleHeaderBtnClick(action) {
    switch (action) {
      case 'edit':
        this.handleEdit();
        break;
      case 'back':
        this.handleBack();
        break;
    }
  }
}
