import { IDashboardConfig, IDCPanel } from "../../../../interfaces/dashboard-page.interface";
import { DashboardConfigPanel } from "./dashboard-config-panel.model";

export class DashboardConfig {
    private _jsonId: number = null;
    private _jsonContent: any = null;
    private _description: string = null;
    private _panels: DashboardConfigPanel = null;

    constructor(option?: IDashboardConfig) {
        if (option) {
            this.set(option);
        }
    }

    set({ ...option }: IDashboardConfig) {
        this._jsonId = option.jsonId;
        this._jsonContent = option.jsonContent;
        this._description = option.description;
    }

    get jsonId() {
        return this._jsonId;
    }


    get jsonContent() {
        return this._jsonContent;
    }


    get description() {
        return this._description;
    }


    get pageTitle() {
        return this._jsonContent.page.pageTitle;
    }



    set panels(args: any) {
        this._panels = new DashboardConfigPanel(args);

        // args.forEach((p: IDCPanel) => {
        //     this._panels.push(new DashboardConfigPanel(p));
        // });
    }

    get panels(): DashboardConfigPanel {
        return this._panels;
    }

    toJSON() {
        return {
            jsonId: this._jsonId,
            jsonContent: this._jsonContent,
            description: this._description
        };
    }


}