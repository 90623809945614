<div class="page-header-wrapper">
    <mat-card class="mat-elevation-z5">
        <div class="page-header-container">
            <div class="inner-header-container">
                <h1 class="header" *ngIf="!isEditTitle">{{pageTitle}}</h1>
                <input matInput type="text" [(ngModel)]="pageTitle" *ngIf="isEditTitle">
                <i class="fa fa-pencil" aria-hidden="true" (click)="isEditTitle = !isEditTitle"
                    *ngIf="isTitleEditable && !isEditTitle"></i>
                <i class="fa fa-close" *ngIf="isTitleEditable && isEditTitle" (click)="isEditTitle = !isEditTitle"></i>
            </div>

            <div class="btn-div">
                <button *ngFor="let button of buttons" mat-raised-button
                    [ngStyle]="{'background-color':button.butnBackgroudColor, 'color': button.butnTextColor}"
                    [ngClass]="'mat-elevation-z3 ' + button.class" (click)="onButtonClick(button.butnAction)"
                    [disabled]="button.disabled">
                    {{button.butnName}}
                </button>
                <!-- <button *ngFor="let button of buttons" mat-raised-button [ngClass]="button.class"
                    (click)="onButtonClick(button.action)" [disabled]="button.disabled">
                    {{button.name}}
                </button> -->
            </div>
        </div>

        <div class="page-filter-container" *ngIf="isDateFilter">
            <div class="formchnge">
                <p *ngFor="let field of formFields">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ field.label }}</mat-label>
                        <ng-container [ngSwitch]="field.type">
                            <!-- Input Type: Date -->
                            <input *ngSwitchCase="'date'" type="date" matInput
                                [ngModel]="field.value | date:'yyyy-MM-dd'" (ngModelChange)="field.value = $event">
                            <!-- Input Type: Text -->
                            <input *ngSwitchCase="'text'" type="text" matInput [ngModel]="field.value"
                                (ngModelChange)="field.value = $event">
                            <!-- Input Type: Number -->
                            <input *ngSwitchCase="'number'" type="number" matInput [ngModel]="field.value"
                                (ngModelChange)="field.value = $event">
                            <!-- Input Type: Select -->
                            <mat-select *ngSwitchCase="'select'" [ngModel]="field.value"
                                (ngModelChange)="field.value = $event">
                                <mat-option *ngFor="let option of field.options" [value]="option">{{ option
                                    }}</mat-option>
                            </mat-select>
                        </ng-container>
                    </mat-form-field>
                </p>
                <p class="sbmitbttn">
                    <button type="submit" class="submit" mat-raised-button (click)="onSearchClick()">{{ searchButtonText
                        }}</button>
                </p>
            </div>
        </div>

    </mat-card>
</div>