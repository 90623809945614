import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PAGE_TYPE } from '../../constants/page/page.constants';
import { Section } from '../../model/page/section.model';
import { PageService } from '../../services/page/page.service';

@Component({
  selector: 'app-section-grid',
  templateUrl: './section-grid.component.html',
  styleUrls: ['./section-grid.component.css']
})
export class SectionGridComponent implements OnInit, AfterViewInit {
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  @Input() data: any;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private pageSvc: PageService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.dataSource.data = this.data.data;
  }

  ngAfterViewInit(): void {
    this.sort.sort({
      id: this.data.defaultSortColumn.fieldName,
      start: this.data.defaultSortColumn.sort,
    } as MatSortable);
    this.dataSource.sort = this.sort;
    this.cdr.detectChanges();
  }

  navigateURL(id, fieldName) {
    this.pageSvc.page.viewConfig.recordId = id;
    this.pageSvc.page.viewConfig.recordFieldName = fieldName;
    this.pageSvc.currentPage = PAGE_TYPE.VIEW; //TODO
  }
}