import { IIndexConfig } from '../../../interfaces';
import { Panel } from './panel.model';
import { Section } from '../section.model';
import { Buttons } from '../viewConfig/button.model';

export class IndexConfig {
    private _jsonId: number = null;
    private _jsonContent: any = null;
    private _description: string = null;

    private _panels: Panel[] = [];
    private _refreshData: boolean = true;
    private _columnNames = [];

    private _buttons: Buttons[] = [];

    constructor(options?: IIndexConfig) {
        if (options) {
            this.set(options);
        }
    }

    set<T extends IIndexConfig>({ ...options }: T): void {
        this._jsonId = options.jsonId;
        this._jsonContent = options.jsonContent;
        this._description = options.description;
    }

    get jsonId() {
        return this._jsonId;
    }

    get jsonContent() {
        return this._jsonContent;
    }

    get description() {
        return this._description;
    }

    setSectionData(data: Array<any>) {
        let i = 0;
        this._panels.forEach((panel: Panel) => {
            panel.sections.forEach((section: Section) => {
                section.data = Object.assign([], data[i]);
                i++;
            });
        });
    }

    get refreshData() {
        return this._refreshData;
    }

    set refreshData(refresh: boolean) {
        this._refreshData = refresh;
    }

    get panels() {
        return this._panels;
    }

    set panels(args: Array<any>) {
        this._panels = [];
        args.forEach((p: any) => {
            this._panels.push(new Panel(p));
        });

        // set column names
        this._columnNames = [];
        this._panels.forEach((p: Panel) => {
            this._columnNames.push(p.sections[0].columns.map((x) => x.fieldName));
        });
    }

    get columnNames() {
        return this._columnNames;
    }

    getDefaultSortColumn(columns: Array<any>) {
        return columns.find((col) => col.sort);
    }

    getPageTitle() {
        return this.jsonContent ? this.jsonContent.pageTitle : '';
    }

    getButnBackgroudColor() {
        return this.jsonContent ? this.jsonContent.butnBackgroudColor : '';
    }

    getButnDisplay() {
        return this.jsonContent ? this.jsonContent.butnDisplay : '';
    }

    getButnName() {
        return this.jsonContent ? this.jsonContent.butnName : '';
    }

    getButnTextColo() {
        return this.jsonContent ? this.jsonContent.butnTextColor : '';
    }

    set buttons(args: Array<any>) {
        this._buttons = [];
        args.forEach((p: any) => {
            this._buttons.push(new Buttons(p));
        });
    }

    get buttons(){
        return this._buttons;
    }
    
    toJSON() {
        return {
            jsonId: this._jsonId,
            jsonContent: this._jsonContent,
            description: this._description,
            panels: this._panels,
            refeshData: this._refreshData,
            columnNames: this._columnNames,
        };
    }
}
