//CORE
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

//CONSTANTS
import { environment } from 'src/environments/environment';
import { END_POINTS } from '../../constants/end-points.constants';

//SERVICES
import { MenuSubmenuService } from '../menu-submenu/menu-submenu.service';

//MODELS
import { Subject } from 'rxjs';
import { IPageDetails } from '../../interfaces';
import { PageDetailsConfig } from '../../model/page/page-details.model';
import { PageConfig } from '../../model/page/page.model';

@Injectable({
  providedIn: 'root',
})
export class PageService {
  private _pageDetails: PageDetailsConfig = new PageDetailsConfig();
  private _page: PageConfig = new PageConfig();
  private _currentPage: string = null;
  currentPageObserver = new Subject();

  constructor(
    private http: HttpClient,
    private menuSubmenuSvc: MenuSubmenuService
  ) {}

  // getter/setter method
  get pageDetails() {
    return this._pageDetails;
  }

  set pageDetails(details) {
    this._pageDetails = details;
  }

  get page() {
    return this._page;
  }

  get currentPage() {
    return this._currentPage;
  }

  set currentPage(pageType) {
    this._currentPage = pageType;
    this.currentPageObserver.next(pageType);
  }

  getPage(id?) {
    const payload = { pageId: id ? id: this.menuSubmenuSvc.selectedMenu.pageId };
    const uri = `${environment.NODE_SERVICE}${END_POINTS.BASE_URL}/page`;
    return this.http.post(uri, payload).subscribe((response: IPageDetails) => {
      this.pageDetails = new PageDetailsConfig(response);
      this.currentPage = this.menuSubmenuSvc.selectedMenu.defaultPage;
    });
  }

  getPageMetadata(jsonId) {
    const payload = { jsonId };
    const uri = `${environment.NODE_SERVICE}${END_POINTS.BASE_URL}/page/config`;
    return this.http.post(uri, payload);
  }

  getPageData(jsonId) {
    const payload = { jsonId };
    const uri = `${environment.NODE_SERVICE}${END_POINTS.BASE_URL}/page/data`;
    return this.http.post(uri, payload);
  }
}
