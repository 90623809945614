export const PORTAL_TYPE_ROUTES = {
  PORTAL: 'portal',
  ADMIN_DASHBOARD: 'adminDashboard',
};

export const MENU_TYPES = {
  DASHBOARD: "Dashboard",
  FORMS: "Forms",
};

export const PORTAL_TYPES = {
  BUSINESS: 'Business',
  ADMIN: 'Admin',
};