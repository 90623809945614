import { IButton } from "src/app/shared/interfaces/button.interface";

export class Buttons {
    private _butnBackgroudColor: string;
    private _butnDisplay: string;
    private _butnName: string;
    private _butnTextColor: string;
    private _butnAction: string;

    constructor({ ...options }: IButton) {
        this.set(options);
    }

    set<T extends IButton>({ ...options }: T): void {
        this._butnBackgroudColor = options.butnBackgroudColor;
        this._butnDisplay = options.butnDisplay
        this._butnName = options.butnName;
        this._butnTextColor = options.butnTextColor;
        this._butnAction = options.butnAction;
    }


    get butnBackgroudColor() {
        return this._butnBackgroudColor;
    }

    get butnDisplay() {
        return this._butnDisplay;
    }

    get butnTextColor() {
        return this._butnTextColor;
    }

    get butnName() {
        return this._butnName;
    }

    get butnAction() {
        return this._butnAction;
    }
}