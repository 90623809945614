import { IColumn } from "../../interfaces";

export class Column {
    private _fieldName: string;
    private _displayName: string;
    private _columnType: string;
    private _sort: string;

    constructor({ ...options }: IColumn) {
        this.set(options);
    }

    set<T extends IColumn>({ ...options }: T): void {
        this._fieldName = options.fieldName;
        this._displayName = options.displayName;
        this._columnType = options.columnType;
        this._sort = options.sort;
    }

    get fieldName() {
        return this._fieldName;
    }

    get displayName() {
        return this._displayName;
    }

    get columnType() {
        return this._columnType;
    }

    get sort() {
        return this._sort;
    }
}
