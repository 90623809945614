import { IDCPanel, IDCSection } from '../../../../interfaces/dashboard-page.interface'
import { DashboardConfigSection } from "./dashboard-config-section.model";

export class DashboardConfigPanel {
    private _panelName: string;
    private _panelMessage: string;
    private _sections: Array<DashboardConfigSection> = [];

    constructor({ ...options }: IDCPanel) {
        this.set(options);
    }

    set({ ...options }: IDCPanel) {
        this._panelName = options.panelName;
        this._panelMessage = options.panelMessage;
        options.sections.forEach((elem: IDCSection) => {
            this._sections.push(new DashboardConfigSection(elem));
        });
    }

    panelName() {
        return this._panelName;
    }
    panelMessage() {
        return this._panelMessage;
    }

    sections() {
        return this._sections;
    }
}