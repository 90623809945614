import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { SpinnerService } from '../shared/services/spinner.service';

import { environment } from '../../environments/environment';
import { CryptoService } from '../shared/services/crytoservice/crypto-service.service';

import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SessionService } from '../shared/services/session/session.service';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(public spinnerService: SpinnerService, private router: Router, private sessionSvc: SessionService) {
        //this.href = this.router.url;

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.spinnerService.show();
        request = request.clone({
            setHeaders: {
                'mode': 'cors',
                authorization: this.sessionSvc.session.accessToken
            },
            body: request.body && (request.headers.get('FormType') !== 'file') ? { data: CryptoService.encryptUsingAES256(JSON.stringify(request.body)) } : request.body
        });
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // if(!(this.router.url =='/adminDashboard/past-appoinments' || this.router.url == '/adminDashboard/appoinments')){
                    this.spinnerService.hide();

                    // }
                }
                return event;
            }),
            catchError(err => {
                this.spinnerService.hide();

                if (['Invalid Token.', 'Token is Expired.'].includes(err.error.errorMessage)) {
                    this.sessionSvc.showLogoutPopup();
                } else {
                    return throwError(err.error);
                }
            }));

    }
}