import { IDataLabels } from "src/app/shared/interfaces/widget-config/widget-config.interface"; 

export class DataLabels {
    private _showDataLabels: boolean;
    private _dataLabelPosition: string;
    private _dataLabelColor: string;
    private _displayUnits: string;
    private _decimalPlaces: number;

    constructor({ ...options }: IDataLabels) {
        this.set(options);
    }

    // Method to set all properties at once
    set({ ...options }: IDataLabels) {
        this._showDataLabels = options.showDataLabels;
        this._dataLabelPosition = options.dataLabelPosition;
        this._dataLabelColor = options.dataLabelColor;
        this._displayUnits = options.displayUnits;
        this._decimalPlaces = options.decimalPlaces;
    }
    
    get showDataLabels(): boolean {
        return this._showDataLabels;
    }
    get dataLabelPosition(): string {
        return this._dataLabelPosition;
    }

    get dataLabelColor(): string {
        return this._dataLabelColor;
    }

    get displayUnits(): string {
        return this._displayUnits;
    }


    get decimalPlaces(): number {
        return this._decimalPlaces;
    }
}