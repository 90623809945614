import { IXAxis } from "src/app/shared/interfaces/widget-config/widget-config.interface"; 

export class XAxis {
    private _title: boolean;
    private _type: string;
    private _startAndEnd: { start: number; end: number };
    private _gridlines: boolean;
    private _lineColor: string;
    private _lineStyle: string;

    constructor({ ...options }: IXAxis) {
        this.set(options);
    }

    set({ ...options }: IXAxis) {
        this._title = options.title;
        this._type = options.type;
        this._startAndEnd = options.startAndEnd;
        this._gridlines = options.gridlines;
        this._lineColor = options.lineColor;
        this._lineStyle = options.lineStyle;
    }

    get title() {
        return this._title;
    }
    set title(value: boolean) {
        this._title = value;
    }

    get type() {
        return this._type;
    }
    set type(value: string) {
        this._type = value;
    }

    get startAndEnd() {
        return this._startAndEnd;
    }
    set startAndEnd(value: { start: number; end: number }) {
        this._startAndEnd = value;
    }

    get gridlines() {
        return this._gridlines;
    }
    set gridlines(value: boolean) {
        this._gridlines = value;
    }

    get lineColor() {
        return this._lineColor;
    }
    set lineColor(value: string) {
        this._lineColor = value;
    }

    get lineStyle() {
        return this._lineStyle;
    }
    set lineStyle(value: string) {
        this._lineStyle = value;
    }
}