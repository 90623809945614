import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/core';
import { ISession, Session } from 'src/app/shared/model/session.model';
import { IUser, User } from 'src/app/shared/model/user.model';
import { SessionService } from 'src/app/shared/services/session/session.service';
import { LoggedinUserService } from 'src/app/shared/services/loggedin-user/loggedin-user.service';
import { environment } from 'src/environments/environment';

interface IClientIdByURL {
  clientId: string;
}

interface ILogin {
  user: IUser;
  session: ISession;
}

interface IRole {
  roleId: string;
  roleName: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild('dataContainer') dataContainer: ElementRef;

  loginForm: FormGroup;
  showprogress = false;

  bgImagesArray: any = [];
  selecteImage = '';
  intervalFunction: any;
  imagaePath = '';
  imgText = '';
  imgPos = '';
  getYear = new Date().getFullYear();

  errorMessage = '';
  clientId = '';

  constructor(
    private formBuilder: FormBuilder,
    private loginSvc: LoginService,
    public dialog: MatDialog,
    private router: Router,
    private loggedInUserSvc: LoggedinUserService,
    private sessionSvc: SessionService
  ) {}

  ngOnInit(): void {
    this.getClientIdByURL();
    this.getImages();
    this.initForm();
  }

  getClientIdByURL() {
    this.loginSvc.getClientIdByURL().subscribe((res: IClientIdByURL) => {
      this.clientId = res.clientId;
      console.log(this.clientId);
    });
  }

  initForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  onSubmit() {
    const data = { ...this.loginForm.value, clientId: this.clientId };
    this.loginSvc.login(data).subscribe(
      (res: ILogin) => {
        console.log(res);
        this.sessionSvc.session = new Session(res.session);
        this.loggedInUserSvc.user = new User(res.user);

        this.loggedInUserSvc.navigateUserAccordingToUserRole();
      },
      (err: any) => {
        console.log(err);
        this.errorMessage = err.errorMessage;
        this.loggedInUserSvc.logout();
      }
    );
  }

  loadLoginImages() {
    this.loginSvc.getLoginpageImages().subscribe(
      (response) => {
        this.bgImagesArray = this.readImagenames(response['records']);
        this.getRandomImage();
      },
      (error) => {}
    );
  }

  getImages() {
    this.imagaePath = environment.IMAGE_PATH;
    this.loadLoginImages();
    this.intervalFunction = setInterval(() => {
      this.getRandomImage();
    }, 60000);
  }

  readImagenames(imgDetailsArray) {
    let imageArray = [];
    imgDetailsArray.forEach((imgObject) => {
      imageArray.push({
        imgName: imgObject.Name + '.jpg',
        imgText: imgObject.Image_Text_1__c + imgObject.Image_Text_2__c,
        imgPos: imgObject.Position__c,
      });
    });
    return imageArray;
  }

  getRandomImage() {
    const ImgObj =
      this.bgImagesArray[Math.floor(Math.random() * this.bgImagesArray.length)];
    this.selecteImage = ImgObj.imgName;
    this.imgText = ImgObj.imgText;
    this.imgPos = ImgObj.imgPos;
    this.dataContainer.nativeElement.innerHTML = this.imgText;
    this.dataContainer.nativeElement.style = this.imgPos;
  }

  ngOnDestroy() {
    clearInterval(this.intervalFunction);
  }
}
