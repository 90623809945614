import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Menu } from 'src/app/shared/model/menu.model';
import { MenuSubmenuService } from 'src/app/shared/services/menu-submenu/menu-submenu.service';

@Component({
  selector: 'app-dashboard-menu',
  templateUrl: './dashboard-menu.component.html',
  styleUrls: ['./dashboard-menu.component.css'],
})
export class DashboardMenuComponent implements OnInit {
  dashboardData: Menu[] = [];

  constructor(private menuSvc: MenuSubmenuService, private router: Router) {}

  ngOnInit() {
    if (this.menuSvc.getDashboardList().length) {
      this.processDashboardMenus(this.menuSvc.getDashboardList());
    } else {
      this.fetchDashboards();
    }
  }

  fetchDashboards() {
    this.menuSvc.getPortalDashboards().subscribe((resp: Menu[]) => {
      console.log('Dashboards list:', resp);
      this.processDashboardMenus(resp);
    });
  }

  processDashboardMenus(menus) {
    this.dashboardData = Object.assign([], menus.find(x => x.title == 'Dashboard')?.subMenus);
    console.log(this.dashboardData);
  }

  navigate(dashboard: Menu) {
    this.menuSvc.selectedMenu = dashboard;
    this.router.navigateByUrl(dashboard.path);
  }
}
