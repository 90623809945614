import { IColumn } from "../../../interfaces";

export class Column {
    private _fieldName: string;
    private _displayName: string;
    private _type: string;
    private _readOnly: boolean;

    constructor(columnData: IColumn) {
        this._fieldName = columnData.fieldName;
        this._displayName = columnData.displayName;
        this._type = columnData.type;
        this._readOnly = columnData.readOnly;
    }

    get fieldName() {
        return this._fieldName;
    }

    get displayName() {
        return this._displayName;
    }

    get type() {
        return this._type;
    }

    get readOnly() {
        return this._readOnly;
    }
}