import { IExportSettings } from "src/app/shared/interfaces/widget-config/widget-config.interface"; 

export class ExportSettings {
    private _exportToExcel: boolean;
    private _exportToPDF: boolean;

    constructor({ ...options }: IExportSettings) {
        this._exportToExcel = options.exportToExcel;
        this._exportToPDF = options.exportToPDF;
    }
    
    get exportToExcel(): boolean {
        return this._exportToExcel;
    }

    get exportToPDF(): boolean {
        return this._exportToPDF;
    }

}
