import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import {
  ACTION_TYPE,
  PAGE_TYPE,
} from 'src/app/shared/constants/page/page.constants';
import { IButton } from 'src/app/shared/interfaces/button.interface';
import { PageService } from 'src/app/shared/services/page/page.service';

@Component({
  selector: 'app-index-config',
  templateUrl: './index-config.component.html',
  styleUrls: ['./index-config.component.css'],
})
export class IndexConfigComponent implements OnInit, AfterViewInit {
  @Output() changePage = new EventEmitter();

  dataSource: Array<any> = [];
  displayedColumns: string[] = [];

  //TODO CHANGES -
  pageMetaData: any;
  panels = [];
  pageTitle = '';
  buttons: IButton[] = [];

  butnName = '';

  constructor(private pageSvc: PageService) {}

  ngOnInit(): void {
    console.log(this.pageSvc.page.indexConfig.jsonId)
    // if the json id is present then the page details was already loaded if not get the json details
    if (this.pageSvc.page.indexConfig.jsonId) {
      // set the loaded data in component
      this.setPageMetaData(this.pageSvc.page.indexConfig.toJSON());
    } else {
      this.getPageMetadata();
    }
  }

  ngAfterViewInit(): void {}

  getPageMetadata() {
    this.pageSvc
      .getPageMetadata(this.pageSvc.pageDetails.indexPageJsonId)
      .subscribe((resp: any) => {
        console.log('getPageMetadata----', resp);
        this.pageSvc.page.setIndexConfig(resp);
        this.pageSvc.page.indexConfig.panels = resp.jsonContent.panels;
        this.pageSvc.page.indexConfig.buttons = resp.jsonContent.buttons
        this.setPageMetaData(resp);
      });
  }

  setPageMetaData(metaData) {
    this.pageMetaData = Object.assign({}, metaData);

    this.pageTitle = this.pageSvc.page.indexConfig.getPageTitle();
    this.butnName = this.pageSvc.page.indexConfig.getButnName();
    this.buttons = this.pageSvc.page.indexConfig.buttons;
    this.displayedColumns = this.pageSvc.page.indexConfig.columnNames;

    if (this.pageSvc.page.indexConfig.refreshData) {
      this.getPageData();
    } else {
      // set the previously fetched panel config and data as it is
      this.panels = this.pageSvc.page.indexConfig.panels;
      console.log(this.panels);
    }
  }

  getPageData() {
    this.pageSvc
      .getPageData(this.pageSvc.pageDetails.indexPageJsonId)
      .subscribe((resp: any) => {
        console.log('getPageData----', resp);
        this.pageSvc.page.indexConfig.setSectionData(resp); //set section data e.g grid data
        this.pageSvc.page.indexConfig.refreshData = false;

        this.panels = this.pageSvc.page.indexConfig.panels;
        console.log(this.panels);
      });
  }

  handleAdd() {
    this.pageSvc.page.formConfig.recordFieldName = null;
    this.pageSvc.page.formConfig.recordValue = null;
    this.pageSvc.page.formConfig.action = ACTION_TYPE.NEW;
    this.pageSvc.currentPage = PAGE_TYPE.EDIT;
  }

  // handleUpdate(record) {
  //   this.pageSvc.page.formConfig.recordFieldName = record;
  //   this.pageSvc.page.formConfig.action = ACTION_TYPE.UPDATE;
  //   this.pageSvc.currentPage = PAGE_TYPE.EDIT;
  // }


  handleClick(action) {
    switch (action) {
      case "add":
        this.handleAdd();
        break;
    }
  }
}
