<app-spinner></app-spinner>
<div id="main-wrapper" class="main-wrapper clearfix">
  <div class="col-md-6 login_section">
    <div class="login-inner-container">
      <header id="header-login-wrapper" role="banner" class="header-login-wrapper">
        <div class="container">
          <div class="row">
            <div class="col-xs-12 intellectportal-site-logo">
              <a role="link" href=""><img class="hide-bg" alt="" src="../assets/images/360intellect-logo.jpg"></a>
            </div>
          </div>
        </div>
      </header>
      <section id="content-wrapper" class="search-layout-wrapper clearfix">
        <div class="login-wrapper">
          <div class="container">
            <div class="row">
              <div class="col-xs-12">
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()"
                  class="intellectportal_form intellectportal_login_form form">
                  <mat-card class="mat-card mat-focus-indicator paddingTop50 paddingBottom50">
                    <p>
                      <mat-form-field appearance="outline" floatlabel="always">
                        <mat-label>Username</mat-label>
                        <input matInput type="text" class="user-input" formControlName="username"
                          placeholder="username">
                      </mat-form-field>
                    </p>
                    <p>
                      <mat-form-field appearance="outline" floatlabel="always">
                        <mat-label>Password</mat-label>
                        <input matInput type="password" class="user-input" formControlName="password"
                          placeholder="password">
                      </mat-form-field>
                    </p>
                    <div class="extra-login-inputs">
                      <p class="submit-container">
                        <button class="submit-btn floatNone" [class.spinner]="showprogress" type="submit" name="submit"
                          [disabled]="loginForm.invalid" mat-raised-button>Sign In </button>
                      </p>
                    </div>
                    <div class="intellectportal-login-message-container" *ngIf="errorMessage">
                      <p class="message-here">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        <span class="error">{{errorMessage}}</span><br />
                      </p>
                    </div>
                  </mat-card>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer id="login-footer-wrapper" class="login-footer-wrapper">
        <div class="container">
          <div class="row">
            <div class="col-xs-12 footer-login-links">
              <p><a target="_blank" href="#">Home</a></p>
              <p><a target="_blank" href="#">Contact Us</a></p>
            </div>
            <!-- <div class="col-xs-12">
              <p>© {{getYear}} {{getCopyRights()}}</p>
            </div> -->
          </div>
        </div>
      </footer>
    </div>
  </div>

  <div class="col-md-6 image_section">
    <img src="{{imagaePath}}{{selecteImage}}" />
    <div class="datacontainer" #dataContainer></div>
  </div>
</div>