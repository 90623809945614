import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { END_POINTS } from 'src/app/shared/constants/end-points.constants';
@Injectable({
    providedIn: 'root',
})
export class LoginService {
    constructor(private http: HttpClient) { }

    getLoginpageImages() {
        let adminQuery = `SELECT Id, Name, Image_Text_1__c, Image_Text_2__c, Image_Text_3__c, Position__c FROM Portal_Image_Config__c`;
        const data = END_POINTS.SF_QUERY + adminQuery;
        const uri = environment.NODE_SERVICE_BASE_URL + END_POINTS.NODE_SERVICE_API_END_POINT + '/execute-query';
        return this.http.post(uri, data);
    }

    getClientIdByURL() {
        // const uri = environment.NODE_SERVICE + END_POINTS.BASE_URL + '/execute-query';
        // const postData = {
        //     endpoint: `${environment.NODE_SERVICE}${END_POINTS.BASE_URL}/clientId`,
        //     method: 'POST',
        //     data: { url: window.location.origin },
        // };
        const uri = `${environment.NODE_SERVICE}${END_POINTS.BASE_URL}/clientId`;
        return this.http.get(uri);
    }

    login(data) {
        // const uri = environment.NODE_SERVICE + END_POINTS.BASE_URL + '/execute-query';
        // const postData = {
        //     endpoint: `${environment.NODE_SERVICE}${END_POINTS.BASE_URL}/login`,
        //     method: 'POST',
        //     data: data,
        // };
        const uri = `${environment.NODE_SERVICE}${END_POINTS.BASE_URL}/login`;
        return this.http.post(uri, { ...data });
    }
}
