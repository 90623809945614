import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { END_POINTS } from '../../constants/end-points.constants';

@Injectable({
  providedIn: 'root',
})
export class ExecuteQueryService {
  constructor(private http: HttpClient) {}

  insertData(tableName, data) {
    const uri = `${environment.NODE_SERVICE}${END_POINTS.BASE_URL}/execute-query/insert`;
    return this.http.post(uri, { tableName, data });
  }

  updateData(tableName, data, recordId) {
    const uri = `${environment.NODE_SERVICE}${END_POINTS.BASE_URL}/execute-query/update`;
    return this.http.post(uri, { tableName, data, recordId });
  }

  executeQuery(query) {
    const uri = `${environment.NODE_SERVICE}${END_POINTS.BASE_URL}/execute-query`;
    return this.http.post(uri, { query });
  }
}
