<app-header [pageTitle]="'Dashboard'"></app-header>
<div class="dashboard-container">
    <div class="row">
        <div *ngFor="let dashboard of dashboardData">
            <div class="dashboard-name">
                <a (click)="navigate(dashboard)">
                    {{dashboard.title}}
                </a>
            </div>
        </div>
    </div>
</div>