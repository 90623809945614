import { IPageDetails } from '../../interfaces';

export class PageDetailsConfig {
    private _pageId: number = null;
    private _pageName: string = null;
    private _indexPageJsonId: number = null;
    private _viewPageJsonId: number = null;
    private _editPageJsonId: number = null;
    private _dashboardJsonId: number = null;

    constructor(options?: IPageDetails) {
        if (options) {
            this.set(options);
        }
    }

    set<T extends IPageDetails>({ ...options }: T): void {
        this._pageId = options.pageId;
        this._pageName = options.pageName;
        this._indexPageJsonId = options.indexPageJsonId;
        this._viewPageJsonId = options.viewPageJsonId;
        this._editPageJsonId = options.editPageJsonId;
        this._dashboardJsonId = options.dashboardJsonId;
    }

    get pageId() {
        return this._pageId;
    }

    get pageName() {
        return this._pageName;
    }

    get indexPageJsonId() {
        return this._indexPageJsonId;
    }

    get viewPageJsonId() {
        return this._viewPageJsonId;
    }

    get editPageJsonId() {
        return this._editPageJsonId;
    }

    get dashboardJsonId() {
        return this._dashboardJsonId;
    }

    toJSON() {
        return {
            pageId: this._pageId,
            pageName: this._pageName,
            indexPageJsonId: this._indexPageJsonId,
            viewPageJsonId: this._viewPageJsonId,
            editPageJsonId: this._editPageJsonId,
            dashboardJsonId: this._dashboardJsonId,
        };
    }
}
