import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { User } from '../../model/user.model';
import { CryptoService } from '../crytoservice/crypto-service.service';
import { environment } from 'src/environments/environment';
import { END_POINTS } from '../../constants/end-points.constants';
import { HttpClient } from '@angular/common/http';
import { LayoutComponent } from 'src/app/admin/component/layout/layout.component';
import { ProfileComponent } from '../../components/pages/profile/profile.component';
import { MenuSubmenuService } from '../menu-submenu/menu-submenu.service';

@Injectable({
  providedIn: 'root',
})
export class LoggedinUserService {
  private static _user: User = new User();
  userData = new Subject<User>();

  constructor(
    private router: Router,
    private http: HttpClient,
    private menuSvc: MenuSubmenuService
  ) {}

  // Getter for user data
  get user() {
    return LoggedinUserService._user;
  }

  // Setter for user data
  set user(userData: User) {
    if (userData.clientId) {
      const data = userData.getUserData();
      localStorage.setItem(
        'user',
        CryptoService.encryptUsingAES256(JSON.stringify(data))
      );
      LoggedinUserService._user = userData;
      this.userData.next(userData);
    } else {
      this.logout();
    }
  }

  isUserDataAvailable() {
    const isDataAvailable = Object.values(LoggedinUserService._user).some(
      (val) => val
    );
    return isDataAvailable;
  }

  navigateUserAccordingToUserRole() {
    // get routes other than redirect route
    const allRoutes = this.router.config.slice(0, -1);
    // last route will be redirect route
    const redirectRoute = this.router.config.pop();

    // we will get this value from backend or we can calculate using role of the user
    const routePath = this.user.roleName
      .toLowerCase()
      .split(' ')
      .map((el, ind) =>
        ind === 0 ? el : el[0].toUpperCase() + el.substring(1, el.length)
      )
      .join('');
    console.log(routePath);

    this.menuSvc.routePath = routePath;
    const newRoute = {
      path: routePath,
      component: LayoutComponent,
      children: [{ path: '', component: ProfileComponent }],
    };
    this.router.resetConfig([...allRoutes, newRoute, redirectRoute]);

    this.router.navigate([`/${routePath}`]);
  }

  logout() {
    // clear all local/session variables
    localStorage.removeItem('user');

    //Clear task obj
    LoggedinUserService._user = new User();

    // remove session
    localStorage.removeItem('session');

    // Redirect to login and refresh page to get updated build code
    this.router.navigate(['/']).then(() => {
      window.location.reload();
    });
  }

  getUserRole() {
    const uri = `${environment.NODE_SERVICE}${END_POINTS.BASE_URL}/user/role`;
    return this.http.get(uri);
  }
}
