<mat-expansion-panel hideToggle #panel="matExpansionPanel" (opened)="panelOpenState = true"
  (closed)="panelOpenState = false" class="mat-elevation-z1" [expanded]="expand">  
  <mat-expansion-panel-header expandedHeight="47px" collapsedHeight="47px" class="">    
    <mat-panel-title class="panel-header">
      <mat-icon *ngIf="!panelOpenState">add</mat-icon>
      <mat-icon *ngIf="panelOpenState">remove</mat-icon>
      <span><strong class="title-font-size">{{ title }}&nbsp;</strong>  {{ subtitle ? subtitle : ''}}
      </span>
    </mat-panel-title>
   
  </mat-expansion-panel-header>
  <ng-content></ng-content>
</mat-expansion-panel>