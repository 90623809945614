import { IWidgets } from '../../../../interfaces/dashboard-page.interface'
import { WidgetConfig } from "../widget-config/widget-config.model";

export class Widgets {
    private _widgetConfig: WidgetConfig;

    constructor({ ...options }: IWidgets) {
        this.set(options);
    }

    set({ ...options }: IWidgets) {
        this._widgetConfig = new WidgetConfig(options.widgetConfig);
    }


    get widgetConfig() {
        return this._widgetConfig;
    }

}