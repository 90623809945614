import { ILegend } from "src/app/shared/interfaces/widget-config/widget-config.interface";

export class Legend {
    private _field: string;
    private _position: string;
    private _showLegend: boolean;

    constructor({ ...options }: ILegend) {
        this.set(options);
    }


    set({ ...options }: ILegend) {
        this._field = options.field;
        this._position = options.position;
        this._showLegend = options.showLegend;
    }


    get field() {
        return this._field;
    }

    get position() {
        return this._position;
    }

    get showLegend() {
        return this._showLegend;
    }

}