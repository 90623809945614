import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  DROPDOWN_TYPE,
  FIELD_TYPE,
} from 'src/app/shared/constants/page/edit-config.constants';
import {
  ACTION_TYPE,
  PAGE_TYPE,
  SECTION_TYPE,
} from 'src/app/shared/constants/page/page.constants';
import { IButton } from 'src/app/shared/interfaces/button.interface';
import { ExecuteQueryService } from 'src/app/shared/services/execute-query/execute-query.service';
import { FormConfigService } from 'src/app/shared/services/form-config/form-config.service';
import { PageService } from 'src/app/shared/services/page/page.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-edit-config',
  templateUrl: './edit-config.component.html',
  styleUrls: ['./edit-config.component.css'],
})
export class EditConfigComponent {
  title: string = null;
  form: FormGroup = new FormGroup({});
  error = {};
  formConfig: any[] = [];
  tableName = null;
  fieldType = FIELD_TYPE;
  dropdownType = DROPDOWN_TYPE;
  recordValue = null;
  fieldName = null;
  action = null;
  editFormTitleField = null;
  recordId = null;
  recordIdFieldName = null;

  buttons: IButton[] = [];
  // headerBtnConfig = [];

  constructor(
    private sharedSvc: SharedService,
    private pageSvc: PageService,
    private formSvc: FormConfigService,
    private executeQuerySvc: ExecuteQueryService
  ) {}

  ngOnInit(): void {
    // const formData = this.pageSvc.page.formConfig.toJSON();
    this.fieldName = this.pageSvc.page.formConfig.recordFieldName;
    this.recordValue = this.pageSvc.page.formConfig.recordValue;
    this.action = this.pageSvc.page.formConfig.action;

    // if the json id is present then the page details was already loaded if not get the json details
    if (this.pageSvc.page.formConfig.jsonId) {
      // set the loaded data in component
      this.setPageMetaData(this.pageSvc.page.formConfig.toJSON());
    } else {
      this.getPageMetadata();
    }
  }

  getPageMetadata() {
    this.pageSvc
      .getPageMetadata(this.pageSvc.pageDetails.editPageJsonId)
      .subscribe((resp: any) => {
        console.log('page Metadata: ', resp);
        this.pageSvc.page.setFormConfig(resp);
        this.setPageMetaData(resp);
      });
  }

  setPageMetaData(metaData) {
    const formData = metaData.jsonContent.sections.find(
      (section) => section.type == SECTION_TYPE.FORM
    );

    if (!formData) return;
    this.title =
      this.action == ACTION_TYPE.NEW ? formData.addTitle : formData.editTitle;
    this.editFormTitleField = formData.editTitleFieldName;

    this.pageSvc.page.formConfig.tableName = formData.tableName;
    this.pageSvc.page.formConfig.buttons = metaData.jsonContent.buttons;

    this.tableName = this.pageSvc.page.formConfig.tableName;
    this.buttons = this.pageSvc.page.formConfig.buttons;

    this.formConfig = formData.rows.map((row) => {
      // get the primary key from the data
      const recordIdField = row.columns.find((col) => col.primaryKey);
      if (recordIdField && this.action == ACTION_TYPE.UPDATE) {
        this.recordIdFieldName = recordIdField.fieldName;
      }

      const allColumns = row.columns.filter((col) => !col.primaryKey);
      const allColumnWidth = allColumns
        .map((col) => col.width)
        .filter((width) => Boolean(width));

      // if the columns have specified the width then return the column as it is
      // if width is not specified then calculate using 12 / number of columns
      if (allColumnWidth.length > 0) {
        return allColumns;
      } else {
        return allColumns.map((column) => {
          return { ...column, width: 12 / allColumns.length };
        });
      }
    });

    console.log('formConfig: ', this.formConfig);

    // initialize the form
    this.initForm();

    if (this.action == ACTION_TYPE.UPDATE) {
      this.getPageData();
    }

    // this loop is to get the dynamic dropdown options
    this.formConfig.map((row, i) => {
      row.map((col, j) => {
        if (
          col.type.toLowerCase() == this.fieldType.DROPDOWN &&
          col.dropdownType != this.dropdownType.FIXED
        ) {
          this.formSvc
            .getDropdownOptions({
              dropdownType: col.dropdownType,
              tableName: col.tableName || '',
              attribute: col.attribute || '',
              columnName: col.columnName || '',
            })
            .subscribe((options) => {
              this.formConfig[i][j].options = options;
            });
        }
      });
    });
  }

  // for edit record
  getPageData() {
    const query = `select * from ${this.tableName} where ${this.fieldName} = ${this.recordValue}`;
    console.log(query);

    this.executeQuerySvc.executeQuery(query).subscribe((data: any) => {
      console.log('Edit config page data: ', data);
      if (data.length) {
        this.setFormData(data[0]);

        // set the record ID... will be used for record update
        this.recordId = data[0][this.recordIdFieldName];

        if (this.editFormTitleField) {
          this.title = `${this.title} - ${data[0][this.editFormTitleField]}`;
        }
      }
    });
  }

  setFormData(data) {
    this.formConfig.forEach((row: any) => {
      row.forEach((field) => {
        if (data[field.fieldName]) {
          this.form.get(field.fieldName).setValue(data[field.fieldName]);
        }
      });
    });
  }

  initForm() {
    const group: any = {};

    this.formConfig.forEach((row) => {
      row.forEach((field) => {
        const allValidators = [];
        const value = {
          value: field.defaultValue || null,
          disabled: field.readOnly || false,
        };

        if (field.required) {
          allValidators.push(Validators.required);
        }
        if (field.type.toLowerCase() == this.fieldType.EMAIL) {
          allValidators.push(Validators.email);
        }
        if (this.fieldType.DROPDOWN != field.type.toLowerCase()) {
          if (field.maxLength) {
            allValidators.push(Validators.maxLength(Number(field.maxLength)));
          }
        }

        group[field.fieldName] =
          allValidators.length == 0
            ? new FormControl(value)
            : allValidators.length == 1
            ? new FormControl(value, allValidators[0])
            : new FormControl(value, allValidators);
      });
    });

    this.form = new FormGroup(group);

  }

  onSubmit() {
    let { value } = this.form;
    console.log(value);

    if (this.action == ACTION_TYPE.NEW) {
      this.executeQuerySvc
        .insertData(this.tableName, value)
        .subscribe((response: any) => {
          console.log(response);
          this.sharedSvc.openSnackBar(response.responseMessage, '');
          this.pageSvc.page.indexConfig.refreshData = true;
          this.pageSvc.currentPage = PAGE_TYPE.INDEX;
        });
    } else if (this.action == ACTION_TYPE.UPDATE) {
      this.executeQuerySvc
        .updateData(this.tableName, value, {
          [this.recordIdFieldName]: this.recordId,
        })
        .subscribe((response: any) => {
          console.log(response);
          this.sharedSvc.openSnackBar(response.responseMessage, '');
          this.pageSvc.page.indexConfig.refreshData = true;
          this.pageSvc.currentPage = PAGE_TYPE.INDEX;
        });
    }
  }

  handleCancel() {
    this.pageSvc.page.indexConfig.refreshData = false;
    this.pageSvc.currentPage = PAGE_TYPE.INDEX;
  }

  handleHeaderBtnClick(action) {
    switch (action) {
      case "cancel":
      case "back":
        this.handleCancel();
        break;
      case "save":
        this.onSubmit();
        break;
    }
  }


  getErrors(fieldName) {
    console.log("fieldName: ", fieldName);
    const allErrors = this.form.controls[fieldName].errors;
    console.log("allErrors: ", allErrors);
    if (this.form.controls[fieldName].errors) {
      const errorName = Object.keys(allErrors)[0];
      if (errorName == 'required') {
        this.error[fieldName] = "This field is required.";
      } else if (errorName == 'email') {
        this.error[fieldName] = "Please enter valid email address.";
      } else if (errorName == 'maxlength') {
        this.error[fieldName] = `Maxmimum Length exceeded. Maxlength: ${allErrors.maxlength.requiredLength}`;
      }
    } else if (Object.keys(this.error).includes(fieldName)) {
      // if no error then delete the error message from obj
      delete this.error[fieldName];
    }
    console.log("this.error: ", this.error);
  }
}
