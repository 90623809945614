export interface IUser {
    clientId: string;
    userId: string;
    userName: string;
    phone: string;
    twoFA: string;
    roleId: string;
    roleName: string;
}

export class User {
    private _clientId: string = null;
    private _userId: string = null;
    private _userName: string = null;
    private _phone: string = null;
    private _twoFA: string = null;
    private _roleId: string = null;
    private _roleName: string = null;

    constructor(options?: IUser) {
        if (options) {
            this.set(options);
        }
    }

    set<T extends IUser>({ ...options }: T): void {
        this._clientId = options.clientId;
        this._userId = options.userId;
        this._userName = options.userName;
        this._phone = options.phone;
        this._twoFA = options.twoFA;
        this._roleId = options.roleId;
        this._roleName = options.roleName;
    }

    // All getter and setters
    get clientId() {
        return this._clientId;
    }

    set clientId(data: string) {
        this._clientId = data;
    }

    get userId() {
        return this._userId;
    }

    set userId(data: string) {
        this._userId = data;
    }

    get userName() {
        return this._userName;
    }

    set userName(data: string) {
        this._userName = data;
    }

    get phone() {
        return this._phone;
    }

    set phone(data: string) {
        this._phone = data;
    }

    get twoFA() {
        return this._twoFA;
    }

    set twoFA(data: string) {
        this._twoFA = data;
    }

    get roleId() {
        return this._roleId;
    }

    set roleId(data: string) {
        this._roleId = data;
    }

    get roleName() {
        return this._roleName;
    }

    set roleName(data: string) {
        this._roleName = data;
    }

    getUserData() {
        return {
            clientId: this._clientId,
            userId: this._userId,
            userName: this._userName,
            phone: this._phone,
            twoFA: this._twoFA,
            roleId: this._roleId,
            roleName: this._roleName,
        };
    }

    isUserDataAvailable() {
        return this._userId && this._clientId;
    }
}
