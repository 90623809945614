import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { END_POINTS } from '../constants/end-points.constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoggedinUserService } from './loggedin-user/loggedin-user.service';
import { Router } from '@angular/router';
import { CryptoService } from './crytoservice/crypto-service.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private http: HttpClient,
    private snackBar: MatSnackBar,
    private loggedInUserSvc: LoggedinUserService,
    private router: Router,
  ) { }

  getNextList(url) {
    const uri = environment.NODE_SERVICE_BASE_URL + END_POINTS.NODE_SERVICE_API_END_POINT + '/execute-query';
    return this.http.post(uri, url);
  }

  authoriseClient(data) {
    const uri = environment.NODE_SERVICE + END_POINTS.BASE_URL + '/authorise';
    return this.http.post(uri, data);
  }

  getObjects(data) {
    const uri = environment.NODE_SERVICE + END_POINTS.BASE_URL + '/getObjects';
    return this.http.post(uri, data);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  openSnackBarError(message: string, action: string){
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: ['red-snackbar'],
    });
  }

  openNewTab(route, otherParams?) {
    console.log('other params to open new tab: ', otherParams);
    console.log('route is: ', route);
    const routeParams = {
      ...otherParams,
      user: this.loggedInUserSvc.user.getUserData(),
    };
    const queryParams = {
      routeParams: CryptoService.encryptUsingAES256(
        JSON.stringify(routeParams)
      ),
    };
    const url = this.router.serializeUrl(
      this.router.createUrlTree([route], { queryParams })
    );
    window.open(url, '_blank');
  }
}
