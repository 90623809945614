<!-- <div class="page-header-wrapper">
    <mat-card class="mat-elevation-z5">
        <div class="page-header-container">
            <h1 class="header"> {{pageTitle}} </h1>
            <div class="btn-div">
                <button mat-raised-button class="primary mat-elevation-z3" (click)="handleAdd()" *ngIf="butnName">{{butnName}}</button>
            </div>
        </div>
    </mat-card>
</div> -->


<app-header *ngIf="pageTitle" [pageTitle]="pageTitle"
    [buttons]="buttons"
    (buttonClick)="handleClick($event)">
</app-header>

<div>
    <div *ngFor="let panel of panels; let i=index ">
        <app-custom-panel title={{panel.panelName}} expand="true" theme="primary">
            <div class="panel-message">
                <h3>{{panel.panelMessage}}</h3>
            </div>
            <div class="section" *ngFor="let section of panel.sections; let j=index;">
                <div>
                    <h3 class="title">{{section.sectionName}}</h3>
                </div>
                <app-section-grid [data]="section"></app-section-grid>
            </div>
        </app-custom-panel>
    </div>
</div>