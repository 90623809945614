import { MatTableDataSource } from '@angular/material/table';
import { IColumn, ISection } from '../../interfaces/panel.interface';
import { Column } from './grid-columns.model';

export class Section {
    private _sectionName: string;
    private _type: string;
    private _tableName: string;
    private _queryFilter: string;
    private _sortOrder: number;
    private _recordCount: number;
    private _counterLabel: string;
    private _gridMaxDisplayRows: number;
    private _fieldNameAlignment: string;
    private _columnAlignment: string;
    private _columns: Array<Column> = [];
    private _data: any;

    constructor({ ...options }: ISection) {
        this.set(options);
    }

    set<T extends ISection>({ ...options }: T): void {
        this._sectionName = options.sectionName;
        this._type = options.type;
        this._tableName = options.tableName;
        this._queryFilter = options.queryFilter;
        this._sortOrder = options.sortOrder;
        this._recordCount = options.recordCount;
        this._counterLabel = options.counterLabel;
        this._gridMaxDisplayRows = options.gridMaxDisplayRows;
        this._fieldNameAlignment = options.fieldNameAlignment;
        this._columnAlignment = options.columnAlignment;
        options.columns.forEach((elem: IColumn) => {
            this._columns.push(new Column(elem));
        });
    }

    get sectionName() {
        return this._sectionName;
    }

    get type() {
        return this._type;
    }

    get tableName() {
        return this._tableName;
    }

    get queryFilter() {
        return this._queryFilter;
    }

    get sortOrder() {
        return this._sortOrder;
    }

    get recordCount() {
        return this._recordCount;
    }

    get counterLabel() {
        return this._counterLabel;
    }

    get gridMaxDisplayRows() {
        return this._gridMaxDisplayRows;
    }

    get columnAlignment() {
        return this._columnAlignment;
    }

    get fieldNameAlignment() {
        return this._fieldNameAlignment;
    }

    get columns() {
        return this._columns;
    }

    get columnDisplayNames() {
        return this._columns.map((x) => x.fieldName);
    }

    get defaultSortColumn() {
        /* SAMPLE Response
        {
            "fieldName": "ClientName",
            "displayName": "Client Name",
            "columnType": "",
            "sort": "asc"
        }*/
        let result = this._columns.find((x) => x.sort);
        return result;
    }

    get data() {
        return this._data;
    }

    set data(args) {
        this._data = args;
    }
}
