import { IColumn, IRow } from "../../../interfaces";
import { Column } from "./columns.model";
export class Row {
    private _columns: Array<Column> = [];

    constructor(rowData: IRow) {
        rowData.columns.forEach((col: IColumn) => {
            this._columns.push(new Column(col));
        });
    }

    get columns() {
        return this._columns;
    }
}