export const FIELD_TYPE = {
  TEXT: 'text',
  DROPDOWN: 'dropdown',
  NUMBER: 'number',
  EMAIL: 'email',
  DATE: 'date',
};

export const DROPDOWN_TYPE = {
  FIXED: 'fixed',
  CODE_TABLE: 'codeTable',
  OTHER_TABLE: 'otherTable',
};

