<!-- 
<div class="page-header-wrapper">
    <mat-card class="mat-elevation-z5">
        <div class="page-header-container">
            <h1 class="header">
                <span *ngIf="data">
                    {{ pageTitle }}
                </span>
            </h1>
            <div class="btn-div">
                <button mat-raised-button class="primary mat-elevation-z3" (click)="handleBack()">
                    << Back </button>
            </div>
        </div>
    </mat-card>
</div> -->

<app-header 
  [pageTitle]="pageTitle" 
  [buttons]="buttons"
  (buttonClick)="handleHeaderBtnClick($event)">
</app-header>


<div class="view-page-container" *ngIf="showPanel && data">
    <div *ngFor="let panel of panels">
        <app-custom-panel title={{panel.panelName}} subtitle={{panel.panelMessage}} expand="true" theme="primary">
            <div *ngFor="let section of panel.sections">
                <div class="row" *ngFor="let row of section.rows">
                    <div class="col-md-12">
                        <div [class]="'col-md-' + (12 / row.columns.length) +' col-sm-' + (12 / row.columns.length) + ' col-md'"
                            *ngFor="let field of row.columns">
                            <span class="field-name"> {{field.displayName}}: </span>
                            <span>{{data[field.fieldName]}}</span>
                        </div>
                    </div>
                </div>

                <div class="btn-div">
                    <button mat-raised-button class="mat-elevation-z3"
                        [style]="{'background-color': section.butnBackgroudColor, 'color':section.butnTextColor}"
                        (click)="handleEdit()">{{section.butnName}}</button>
                </div>
            </div>
        </app-custom-panel>
    </div>
</div>